import * as React from 'react';
import { graphql } from "gatsby";

import Layout from '../components/layout.js';
import Hero from '../components/hero.js';
import '../styles/case-studies.scss';
import CaseStudyTile from '../components/case-study-tile.js';
import Testimonials from '../components/testimonials.js';
import Video from '../components/video.js';
import Contact from '../components/contact.js';
import Logos from '../components/logos.js';

const Home = ({ data }) => {

  const caseStudies = [
    {
      client: "COAT Paints",
      imageName: "coat-tins",
      tags: ['Development', 'SEO', 'Analytics'],
      blurb: "54 Bit Studios rebuilt Coat Paint’s website to bring their store into the cutting edge of ecommerce technologies.",
      image: data.allFile.nodes.find(node => node.name === "coat-tins") ? data.allFile.nodes.find(node => node.name === "coat-tins").childImageSharp.gatsbyImageData : null,
      link: '/case-studies/coat-paints'
    },
    {
      client: "Jubel",
      imageName: "jubel-beer",
      tags: ['Development', 'SEO'],
      blurb: "Jubel are the UK's fastest growing beer brand. 54 Bit Studios helped them scale their website to meet the demand.",
      image: data.allFile.nodes.find(node => node.name === "jubel-beer") ? data.allFile.nodes.find(node => node.name === "jubel-beer").childImageSharp.gatsbyImageData : null,
      link: '/case-studies/jubel'
    },
    {
      client: "Russell & Atwell",
      imageName: "chocolates",
      tags: ['Design', 'Development', 'SEO', 'CRO'],
      blurb: "How 54 Bit Studios helped Russell & Atwell elevate their website for faster speed, improved efficiency, and a seamless customer experience.",
      image: data.allFile.nodes.find(node => node.name === "chocolates") ? data.allFile.nodes.find(node => node.name === "chocolates").childImageSharp.gatsbyImageData : null,
      link: '/case-studies/russell-and-atwell',
      type: 'Case Study'
    }
  ]

  const caseStudiesWithImages = caseStudies.map(study => {
    const image = data.allFile.nodes.find(node => node.name === study.imageName);
    return { ...study, image: image ? image.childImageSharp.gatsbyImageData : null };
  });

  const testimonials = [
    {
      text: "I cannot over-emphasise how happy we are with 54 Bit Studios. They deliver on every project to a standard (and at a speed) well in advance of our expectations. This level of service guarentees that we're a customer for life!",
      client: {
        name: "Michael Jones",
        position: "Co-Founder",
        company: "HouseOf",
        image: data.allFile.nodes.find(node => node.relativePath.includes('houseof') && node.name === "profile")?.childImageSharp.gatsbyImageData || null
      }
    },
    {
      text: "Beyond delivering results for us, 54 Bit Studios have been enjoyable to work with, super professional and performance-driven. Unlike other agencies, they deliver what they say they are going to deliver, time and time again. I highly recommend.",
      client: {
        name: "Laura Harnett",
        company: "Seep",
        position: "Founder",
        image: data.allFile.nodes.find(node => node.relativePath.includes("seep") && node.name === "profile")?.childImageSharp.gatsbyImageData || null
      }
    },
    {
      text: "I think the thing that makes 54 Bit Studios better than competitors is their attitude - that ‘anything is possible’ mindset. It's always about solutions, not problems and this is quite uncommon in the web development industry.",
      client: {
        name: "Rob Abrahams",
        company: "COAT Paints",
        position: "Co-Founder",
        image: data.allFile.nodes.find(node => node.relativePath.includes("coat") && node.name === "profile")?.childImageSharp.gatsbyImageData || null
      }
    },
    {
      text: "I’m very likely to recommend them... they did a great job for us on the site. We know they work hard and they’re always there to talk through stuff. We felt like we were in safe hands.",
      client: {
        name: "Giles Atwell",
        company: "Russell & Atwell",
        position: "Co-Founder",
        image: data.allFile.nodes.find(node => node.relativePath.includes("russell-and-atwell") && node.name === "profile")?.childImageSharp.gatsbyImageData || null
      }
    }]

  return (
    <Layout background="mustard">
      <Hero />
      <section className='ff case-studies' id='case-studies'>
        <h2 className='ff ff-section-header'>Case Studies</h2>
        <div className="grid">
          {caseStudiesWithImages.map(study => (
            <CaseStudyTile
              key={study.client}
              image={study.image}
              client={study.client}
              blurb={study.blurb}
              tags={study.tags}
              link={study.link}
              type="Case Study"
            />
          ))}
        </div>
      </section>
      <Testimonials testimonials={testimonials} />
      <Video />
      <Contact heading="Get in touch" subheading="If you have a project in mind, or you just want to find out more about how we can help, please fill out the form below and we'll get in touch." />
      <Logos />
    </Layout>

  )
}

export default Home

export const query = graphql`
    query {
        allFile(filter: { sourceInstanceName: { eq: "assets" } }) {
            nodes {
                name
                relativePath
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED, width: 1200)
                }
            }
        }
    }
`;